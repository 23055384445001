import "../styles/Note.css"

export default function NoteItem({
    note= 0,
    noteMax= 1,
    onChange= undefined
}) {

  function changeNote(e, nb) {
    e.preventDefault();
    onChange(nb);
  }

  return (
    <ul className="step">
      {[...Array(noteMax + 1).keys()].map((nb) => (
        <li key={nb} className={nb === note ? "step-item active" : "step-item"}>
          <a href={"#" + nb} className="tooltip note" data-tooltip={nb} onClick={(e) => changeNote(e, nb)}>
            {nb}
          </a>
        </li>
      ))}
    </ul>
  );
}
