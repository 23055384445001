import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { selectAllDocuments, getDocuments, addDocument, getDocument, deleteDocument } from "../store/documents";
import { post } from "../utils/fonctions";
import ListElement from "./ListElement";

export default function Documents() {
  const [document, setDocument] = useState();
  const documents = useSelector(selectAllDocuments);
  const documentsStatus = useSelector((state) => state.documents.status);
  const dispatch = useDispatch();
  const isAdmin = useSelector((state) => state.user.isAdmin);

  useEffect(() => {
    if (documentsStatus === "idle") {
      dispatch(getDocuments());
    }
  }, [dispatch, documentsStatus]);

  function changeDocument(files) {
    if (files && files.length > 0) {
      setDocument(files[0]);
    } else {
      setDocument();
    }
  }
  async function supprimeDocument(document) {
    if (!document) {
      return;
    }
    await dispatch(deleteDocument(document)).unwrap();
    dispatch(getDocuments());
  }

  async function downloadDocument(document) {
    if (!document) {
      return;
    }
    await dispatch(getDocument(document)).unwrap();
  }

  async function uploadDocument() {
    if (!document) {
      return;
    }
    await dispatch(addDocument(document)).unwrap();
    dispatch(getDocuments());
  }

  return (
    <>
      <div className="columns">
        <div className="column col-3">
          <div className="form-group">
            <label className="form-label" forhtml="input-file">
              Documents
            </label>{" "}
            {isAdmin && (
              <>
                <input className="form-input" type="file" onChange={(e) => changeDocument(e.target.files)} />
                <button className="btn btn-secondary longButton mt-2" onClick={() => uploadDocument()}>
                  Upload
                </button>
              </>
            )}
          </div>
        </div>
        <div className="column col-8">
          {documents && documents.length > 0 && (
            <ListElement elements={documents} keyPrefix={"file"} canDelete={isAdmin} onDelete={(d) => supprimeDocument(d)}>
              {(d) => (
                <div className="columns">
                  <div className="column col-auto">
                    <button className="btn btn-sm btn-primary input-group-btn s-circle" onClick={() => downloadDocument(d)}>
                      <i className="icon icon-download"></i>
                    </button>
                  </div>
                  <div className="column col">{d}</div>
                </div>
              )}
            </ListElement>
          )}
        </div>
      </div>
    </>
  );
}
