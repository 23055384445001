import { useEffect, useState } from "react";

export default function Chapitres({ questions = [], container = true }) {
  const [chapitres, setChapitres] = useState([]);

  useEffect(() => {
    const chapitres = questions
      ?.map((q) => q.chapitre)
      ?.filter(c => c && c !== "")
      ?.filter((c, i, a) => a.findIndex((v) => v === c) === i);
    chapitres.sort();
    setChapitres(chapitres);
  }, [questions]);

  return (
    (container && (
      <div className="mt-1 chapitreContainer">
        {chapitres.map((c) => (
          <span key={c} className="chapitre">
            {c}
          </span>
        ))}
      </div>
    )) ||
    chapitres.map((c) => (
      <span key={c} className="chapitre">
        {c}
      </span>
    ))
  );
}
