export default function List({
  elements = [],
  children = undefined,
}) {
  return (
    <ul className="menu">
      {
        elements.map((e, i) => (
          <li key={i} className="menu-item" >
            {children(e)}
          </li>
        ))
      }
    </ul>
  );
}
