import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../utils/fonctions";

const initialState = {
  groupes: [],
  status: "idle",
  error: null,
};

const groupesSlice = createSlice({
  name: "groupes",
  initialState,
  reducers: {
    resetGroupes: (state) => {
      state.status = "idle";
      state.groupes = [];
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getGroupes.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getGroupes.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.groupes = action.payload;
      })
      .addCase(getGroupes.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
  },
});

export const { resetGroupes } = groupesSlice.actions

export const selectAllGroupes = (state) => state.groupes.groupes;

export const getGroupes = createAsyncThunk("get/groupes", async () => {
  return await get("/api/groupes");
});

export const addGroupe = createAsyncThunk("posts/groupe", async (groupe) => {
  return await post(`/api/groupes`, [ groupe ]);
});

export const addGroupes = createAsyncThunk("posts/groupes", async (groupes) => {
  return await post(`/api/groupes`, groupes);
});

export default groupesSlice.reducer;