import QuestionDevoir from "./QuestionDevoir";

export default class Devoir {
  _id = "";
  date = "";
  classe = "";
  sujet = "";
  source = "";
  noteDS = 0;
  utilise = false;
  verrouille = false;
  questions = [];

  constructor({
    _id = "",
    date = "",
    classe = "",
    sujet = "",
    source = "",
    noteDS = 0,
    utilise = false,
    verrouille = false,
    questions = [],
  }) {
    this._id = _id;
    this.date = date;
    this.classe = classe;
    this.sujet = sujet;
    this.source = source;
    this.noteDS = noteDS;
    this.utilise = utilise;
    this.verrouille = verrouille;
    this.questions = questions.map(q => new QuestionDevoir(q));
  }

  get nom() {
    return `${this.date} - ${this.classe} - ${this.sujet}`;
  }

  get classeSeulement() {
    return this.classe?.substring(0, this.classe?.indexOf("_")) ?? "";
  }
  set classeSeulement(value) {
    this.classe = `${value}_SII`;
  }

  static sort(a, b) {
    const diff = a.classe.localeCompare(b.classe);
    return diff === 0 ? a.date.localeCompare(b.date) : diff;
  }
}

