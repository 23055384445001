import '../styles/Accordion.css'

export default function Accordion({
  children = undefined,
  keySuffix = "key",
  title = "",
  headerTemplate = undefined,
  defaultChecked = false,
}) {
  return (
    <div className="accordion myaccordion">
      <input
        type="checkbox"
        id={"accordion-" + keySuffix}
        name={"accordion-" + keySuffix}
        defaultChecked={defaultChecked}
        hidden
      />
      {headerTemplate ? (
        <div className="container-lg bg-secondary">
          <div className="columns">
            <div className="column col-auto">
              <label
                className="accordion-header"
                htmlFor={"accordion-" + keySuffix}
              >
                <i className="icon icon-arrow-right mr-1"></i>
                {title}
              </label>
            </div>
            <div className="column">
              {headerTemplate}
            </div>
          </div>
        </div>
      ) : (
        <label
          className="accordion-header bg-secondary"
          htmlFor={"accordion-" + keySuffix}
        >
          <i className="icon icon-arrow-right mr-1"></i>
          {title}
        </label>
      )}
      <div className="myaccordion-body" >{children}</div>
    </div>
  );
}
