import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../utils/fonctions";

const initialState = {
  cours: undefined,
  status: "idle",
  error: null,
};

const coursSlice = createSlice({
  name: "cours",
  initialState,
  reducers: {
    resetCours: (state) => {
      state.status = "idle";
      state.cours = undefined;
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getCours.pending, (state, action) => {
        state.status = "loading";
        state.cours = undefined;
        state.error = null;
      })
      .addCase(getCours.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.cours = action.payload ?? { id: action.meta.arg, intitule: action.meta.arg, parties:[] };
      })
      .addCase(getCours.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetCours } = coursSlice.actions;

export const selectCours = (state) => state.cours.cours;

export const getCours = createAsyncThunk("get/cours", async (id) => {
  return await get(`/api/cours/${id}`);
});

export default coursSlice.reducer;
