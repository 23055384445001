export default function Modal({
  children = undefined,
  keySuffix = "key",
  title = "",
  active = false,
  onCancel = undefined,
  onValidate = undefined,
}) {
  function close(e) {
    e.preventDefault();
    onCancel?.();
  }
  function annuler() {
    onCancel?.();
  }
  function valider() {
    onValidate?.();
  }

  return (
    <div
      className={active ? "modal active" : "modal"}
      id={"modal-" + keySuffix}
    >
      <a
        href="?"
        className="modal-overlay"
        aria-label="Close"
        onClick={(e) => close(e)}
      ></a>
      <div className="modal-container">
        <div className="modal-header">
          <a
            href="?"
            className="btn btn-clear float-right"
            aria-label="Close"
            onClick={(e) => close(e)}
          ></a>
          <div className="modal-title h5">{title}</div>
        </div>
        <div className="modal-body">
          <div className="content">{children}</div>
        </div>
        <div className="modal-footer">
          <div className="container">
            <div className="columns">
              <div className="column col-4" />
              <div className="column col-4">
                <button className="btn btn-dark longButton" onClick={() => annuler()}>
                  Annuler
                </button>
              </div>
              <div className="column col-4">
                <button className="btn btn-primary longButton" onClick={() => valider()}>
                  Valider
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
