import "../../styles/Devoirs.css";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { getDicoSII } from "../../store/dicoSiiNsi";
import DevoirHeader from "./DevoirHeader";
import ExerciceQuestions from "./ExerciceQuestions";

export default function DevoirItem({ devoirInitial = undefined, onChange = undefined }) {
  const [devoir, setDevoir] = useState({});
  const dicoSIIStatus = useSelector((state) => state.dicos.statusDicoSII);
  const dicoSII = useSelector((state) => state.dicos.dicoSII);
  const dispatch = useDispatch();

  useEffect(() => {
    if (dicoSIIStatus === "idle") {
      dispatch(getDicoSII());
    }

    setDevoir(devoirInitial);
  }, [dispatch, dicoSIIStatus, devoirInitial]);

  function changeDevoir(devoir) {
    onChange(devoir);
  }

  return (
    devoir && (
      <div className="container">
        <DevoirHeader devoir={devoir} onChange={changeDevoir}/>
        <ExerciceQuestions
          devoir={devoir}
          dico={dicoSII}
          onChange={changeDevoir}
        />
      </div>
    )
  );
}
