import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { NavLink, Link, useParams } from "react-router-dom";
import { getCours, selectCours, resetCours } from "../store/cours";
import { getDicoNSI, getDicoSII } from "../store/dicoSiiNsi";
import { MathJax } from "better-react-mathjax";
import { sortDicoIds, get, download } from "../utils/fonctions";

export default function Cours() {
  const [menus, setMenus] = useState();
  const dicoNSIStatus = useSelector((state) => state.dicos.statusDicoNSI);
  const dicoNSI = useSelector((state) => state.dicos.dicoNSI);
  const dicoSIIStatus = useSelector((state) => state.dicos.statusDicoSII);
  const dicoSII = useSelector((state) => state.dicos.dicoSII);
  const coursStatus = useSelector((state) => state.cours.status);
  const cours = useSelector(selectCours);
  const { idCours } = useParams();
  const { matiere } = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    if (dicoNSIStatus === "idle") {
      dispatch(getDicoNSI());
    }
    if (dicoSIIStatus === "idle") {
      dispatch(getDicoSII());
    }
    if (idCours && cours && idCours !== cours?.id) {
      dispatch(resetCours());
    }
    if (coursStatus === "idle") {
      dispatch(getCours(idCours));
    }
    if (dicoSII && dicoNSI && idCours && !menus) {
      construitMenu(dicoSII, dicoNSI, idCours, matiere);
    }
  }, [dispatch, dicoNSI, dicoNSIStatus, dicoSII, dicoSIIStatus, idCours, cours, coursStatus, matiere, menus]);

  function openPdf() {
    get(`/static/pdf/cours/${idCours}.pdf`, { responseType: "blob" } )
      .then((blob) => download(`${idCours}.pdf`, blob));
  }

  function construitMenu(dicoSII, dicoNSI, idCours, matiere) {
    const dico = matiere === "SII" ? dicoSII : dicoNSI;
    const cours = dico.dico.filter((c) => c.id === idCours).shift();
    if (!cours) {
      return;
    }
    const coursDuThemes = dico.dico.filter((c) => c.theme === cours.theme).sort((a, b) => -sortDicoIds(a.id, b.id));
    setMenus(coursDuThemes);
  }

  function affichageImage(p) {
    return (
      <>
        <div>
          <p dangerouslySetInnerHTML={{ __html: p.contenu }} />
        </div>
        <div>
          {p.image.map((i) => (
            <img className="w3-center" src={i} alt={i} style={{ width: "auto" }}></img>
          ))}
        </div>
      </>
    );
  }

  function affichageClassique(p) {
    console.log(p.contenu);
    
    return p.contenu && <p dangerouslySetInnerHTML={{ __html: p.contenu }} />;
  }

  function affichageSousParties(sousPartie, partieIndex, index) {
    return (
      <div className="mx-2" key={"p" + partieIndex + "ss" + index}>
        {sousPartie.titre && sousPartie.titre.trim() !== "" && (
          <div className="bg-secondary">
            <h6 className="ml-2 py-2" dangerouslySetInnerHTML={{ __html: sousPartie.titre }}></h6>
          </div>
        )}
        {sousPartie.image ? affichageImage(sousPartie) : affichageClassique(sousPartie)}
      </div>
    );
  }

  function affichageParties(partie, index) {
    return (
      <div key={"p" + index}>
        {partie.titre && partie.titre.trim() !== "" && (
          <div className="bg-secondary text-primary">
            <h5 className="ml-2 py-2" dangerouslySetInnerHTML={{ __html: partie.titre }}></h5>
          </div>
        )}
        {partie.image ? affichageImage(partie) : affichageClassique(partie)}
        {partie.sousParties && partie.sousParties.map((p, i) => affichageSousParties(p, index, i))}
      </div>
    );
  }

  function affichageCours(cours) {
    return (
      <div style={{ maxWidth: "1000px", margin: "auto" }}>
        <div className="float-right mr-2">
          <button className="btn btn-primary mb-2 " onClick={() => openPdf()}>
            <svg width="18" height="18" fill="currentColor" viewBox="0 0 16 16">
              <use xlinkHref="/iconSprites.svg#filetype-pdf" />
            </svg>
          </button>
        </div>
        <div className="bg-primary text-center p-2 m-2">
          <h1 className="m-2">{cours.intitule}</h1>
        </div>
        {cours.parties.map((p, i) => affichageParties(p, i))}
      </div>
    );
  }

  function menuItem(menu) {
    return (
      <NavLink to={`/cours/${matiere}/${menu.id}`} className={({ isActive, isPending }) => (isActive ? "bg-primary text-light p-1" : "p-2")}>
        {menu.id} - {menu.nom.replace("<br>", " ")}
      </NavLink>
    );
  }

  function affichageMenu(menus) {
    return (
      <ul className="nav hide-sm">
        {menus.map((m) => (
          <li key={m.id} className="nav-item">
            {menuItem(m)}
          </li>
        ))}
      </ul>
    );
  }

  return (
    <MathJax hideUntilTypeset="every" dynamic >
      <div className="container">
        <div className="columns">
          <div className="column col-2 col-md-3 col-sm-12">{menus && affichageMenu(menus, matiere)}</div>
          <div className="column col-10 col-md-9 col-sm-12">{cours && affichageCours(cours)}</div>
        </div>
      </div>
    </MathJax>
  );
}
