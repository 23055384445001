import { createSlice } from "@reduxjs/toolkit";
import { getUser, removeUser, setUser } from "../utils/fonctions";

export const userSlice = createSlice({
  name: "user",
  initialState: {
    isConnected: false,
    isAdmin: false,
    user: {},
  },
  reducers: {
    disconnect: (state) => {
      removeUser();
      state.user = {};
      state.isConnected = false;
      state.isAdmin = false;
    },
    checkStoredUser: (state) => {
      const user = getUser();
      if (user) {
        state.user = user;
        state.isAdmin = state.user.admin;
        state.isConnected = true;
      }
    },
    connect: (state, action) => {
      state.user = action.payload;
      state.isConnected = true;
      state.isAdmin = action.payload.admin;
      setUser(state.user);
    },
  },
});

// Action creators are generated for each case reducer function
export const { checkStoredUser, disconnect, connect } = userSlice.actions;

export default userSlice.reducer;
