import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import Note from "../../models/Note";
import Devoir from "../../models/Devoir";
import AdminTemplate from "./AdminTemplate";
import ScrollView from "../ScrollView";
import NoteItem from "../NoteItem";
import Select from "../Select";
import List from "../List";
import { selectAllEleves, getEleves, getEleveNotes, addEleveNote, selectEleveNotes } from "../../store/eleves";
import { selectAllDevoirs, getDevoirs } from "../../store/devoirs";
import { selectDevoirNotes, getDevoirNotes } from "../../store/notes";

function PrepareDevoirNotes(devoir, notes, eleves, sort = false) {
  const elevesClasse = eleves.filter((e) => e.groupes.findIndex((c) => c.classe === devoir.classeSeulement) >= 0);
  return elevesClasse.map((eleve) => {
    var note = notes.filter((n) => n.login === eleve.login).shift();
    if (!note) {
      note = new Note({
        date: devoir.date,
        groupe: devoir.classe,
        login: eleve.login,
        nom: eleve.nom,
        prenom: eleve.prenom,
        sujet: devoir.sujet,
        matiere: devoir.classeSeulement,
        noteDS: devoir.noteDS,
        noteEleve: 0,
        questions: devoir.questions.map((q) => {
          return {
            numero: q.numero,
            pointQuestion: q.pointQuestion,
            pointEleve: 0,
            acquis: false,
            origine: q,
          };
        }),
      });
    } else {
      note = new Note(note);
      note.nom = eleve.nom;
      note.prenom = eleve.prenom;
      note.questions.forEach((question) => {
        question.origine = devoir.questions.filter((q) => q.numero === question.numero).shift();
      });
    }
    if (sort) {
      note.questions.reverse();
    }
    return note;
  });
}

export default function AdminEvaluation() {
  const [selectedDevoirId, setSelectedDevoirId] = useState("");
  const [selectedDevoir, setSelectedDevoir] = useState();
  const [devoirNotes, setDevoirNotes] = useState("");
  const [selectedNote, setSelectedNote] = useState();
  const [devoirsEval, setDevoirsEval] = useState();
  const [sort, setSort] = useState(false);

  const dispatch = useDispatch();
  const notes = useSelector(selectEleveNotes);
  const eleves = useSelector(selectAllEleves);
  const devoirNotesRaw = useSelector(selectDevoirNotes);
  const devoirNotesStatus = useSelector((state) => state.notes.status);
  const devoirs = useSelector(selectAllDevoirs);
  const elevesStatus = useSelector((state) => state.eleves.status);
  const devoirsStatus = useSelector((state) => state.devoirs.status);

  useEffect(() => {
    if (elevesStatus === "idle") {
      dispatch(getEleves());
    }
    if (devoirsStatus === "idle") {
      dispatch(getDevoirs());
    }
    setDevoirsEval(devoirs.filter((d) => !d.verrouille));

    if (selectedDevoir && eleves) {
      const devoirNotes = PrepareDevoirNotes(selectedDevoir, devoirNotesRaw, eleves, sort);
      setDevoirNotes(devoirNotes);
    }
  }, [dispatch, elevesStatus, devoirsStatus, devoirs, devoirNotesRaw, selectedDevoir, eleves, sort]);

  async function changeSelectDevoir(devoirId) {
    const devoirRaw = devoirs.filter((d) => d._id === devoirId).shift();
    if (devoirRaw) {
      const devoir = new Devoir(devoirRaw);
      dispatch(getDevoirNotes(devoir.date));
      setSelectedDevoir(devoir);
    }
    setSelectedDevoirId(devoirId);
  }

  async function changeSelectedNote(e, note) {
    e.preventDefault();
    setSelectedNote(note);
    getEleveNotes(note.login);
  }

  function changeNote(q, val) {
    const noteChanged = new Note(selectedNote);
    const questionChange = noteChanged.questions.filter((question) => question.numero === q.numero).shift();
    questionChange.pointEleve = val;
    questionChange.acquis = questionChange.pointEleve === questionChange.pointQuestion;
    noteChanged.noteEleve = noteChanged.questions.reduce((a, q) => a + q.pointEleve, 0);
    setSelectedNote(noteChanged);
  }

  function changeSort() {
    setSort(!sort);
    selectedNote.questions.reverse();
  }

  function changeAcquis(q) {
    const noteChanged = new Note(selectedNote);
    const questionChange = noteChanged.questions.filter((question) => question.numero === q.numero).shift();
    questionChange.acquis = !questionChange.acquis;
    setSelectedNote(noteChanged);
  }

  async function sauvegarder() {
    const note = new Note(selectedNote);
    await dispatch(addEleveNote(note)).unwrap();
    dispatch(getDevoirNotes(selectedDevoir.date));
  }

  return (
    <AdminTemplate>
      <div className="container">
        <div className="columns">
          <div className="column col-1 col-md-2">
            <label className="form-label" htmlFor="input-example-3">
              Devoir
            </label>
          </div>
          <div className="column col-11 col-md-10">
            <Select
              elements={devoirsEval}
              select={selectedDevoirId}
              emptyElement={true}
              emptyElementLabel={"Choisir un devoir à évaluer"}
              onSelectChange={(d) => changeSelectDevoir(d)}
              keySelector={(c) => c._id}
              valueSelector={(c) => c._id}
              labelSelector={(c) => c.nom}
            />
          </div>
          {selectedDevoirId !== "" && devoirNotes && (
            <div className="column col-4 col-md-12">
              <label className="form-label" htmlFor="input-example-3">
                Classe {selectedDevoir.classeSeulement}
              </label>
              <ScrollView maxHeight="calc(100vh - 250px)" margin="0 0 0 0">
                <List elements={devoirNotes} keyPrefix={"eleve"}>
                  {(c) => (
                    <a href={"#" + c.login} onClick={(e) => changeSelectedNote(e, c)} className={c.login === selectedNote?.login ? "active" : ""}>
                      <div className="container">
                        <div className="columns">
                          <div className="column col-1 mt-2">
                            {c.estEvaluer && <i className="icon icon-check text-success"></i>}
                            {!c.estEvaluer && <i className="icon icon-cross text-error"></i>}
                          </div>
                          <div className="column col-9 mt-1">
                            {c.nom} {c.prenom}
                          </div>
                          {c.login !== selectedNote?.login && <div className="column col-2 mt-1">{c.noteEleve}</div>}
                          {c.login === selectedNote?.login && <div className="column col-2 mt-1">{selectedNote.noteEleve}</div>}
                        </div>
                      </div>
                    </a>
                  )}
                </List>
              </ScrollView>
            </div>
          )}
          {selectedNote && (
            <div className="column col-8 col-md-12 mt-1">
              <div className="columns">
                <div className="column col-11">
                  {selectedNote?.nom} {selectedNote?.prenom}
                </div>
                <div className="column col-1">
                  <button className={"btn btn-action btn-sm" + (sort ? " active" : "")} onClick={() => changeSort()}>
                    <i className={sort ? "icon icon-upward" : "icon icon-downward"}></i>
                  </button>
                </div>
                <div className="column col-12">
                  <ScrollView maxHeight="calc(100vh - 250px)" margin="10 0 0 0" overflowX="hidden">
                    {selectedNote?.questions?.map((q, i) => (
                      <div key={i} className="columns">
                        <div className="column col-2">
                          <span className="numero tooltip tooltip-right" data-tooltip={q.origine.chapitre + " - " + q.origine.competence + " - " + q.origine.notion}>
                            {q.numero}
                          </span>
                        </div>
                        <div className="column col-auto">
                          <NoteItem note={q.pointEleve} noteMax={q.pointQuestion} onChange={(val) => changeNote(q, val)} />
                        </div>
                        <div className="column col-1">
                          <div className="form-group">
                            <label className="form-switch">
                              <input type="checkbox" checked={q.acquis} onChange={() => changeAcquis(q)} />
                              <i className="form-icon"></i>Acquis
                            </label>
                          </div>
                        </div>
                      </div>
                    ))}
                  </ScrollView>
                </div>
                <div className="column col-12">
                  <button disabled={!selectedDevoir || selectedDevoir.sujet === ""} className="btn btn-primary longButton mb-2" onClick={() => sauvegarder()}>
                    Sauvegarder
                  </button>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </AdminTemplate>
  );
}
