import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, post } from "../utils/fonctions";

const initialState = {
  eleves: [],
  status: "idle",
  error: null,
  notes: [],
  statusNote: "idle",
};

const elevesSlice = createSlice({
  name: "eleves",
  initialState,
  reducers: {
    resetEleves: (state) => {
      state.status = "idle";
      state.statusNote = "idle";
      state.eleves = [];
      state.notes = [];
      state.error = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getEleves.pending, (state, action) => {
        state.status = "loading";
      })
      .addCase(getEleves.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.eleves = action.payload;
      })
      .addCase(getEleves.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(getEleveNotes.pending, (state, action) => {
        state.statusNote = "loading";
      })
      .addCase(getEleveNotes.fulfilled, (state, action) => {
        state.statusNote = "succeeded";
        state.notes = action.payload;
      })
      .addCase(getEleveNotes.rejected, (state, action) => {
        state.statusNote = "failed";
        state.error = action.error.message;
      });
  },
});

export const { resetEleves } = elevesSlice.actions

export const selectAllEleves = (state) => state.eleves.eleves;
export const selectEleveNotes = (state) => state.eleves.notes;

export const getEleves = createAsyncThunk("get/eleves", async () => {
  return await get("/api/eleves");
});

export const getEleveNotes = createAsyncThunk("get/eleveNotes", async (login) => {
  return await get(`/api/eleves/${login}/notes`);
});

export const addEleve = createAsyncThunk("posts/eleve", async (eleve) => {
  return await post(`/api/eleves`, [ eleve ]);
});

export const addEleves = createAsyncThunk("posts/eleves", async (eleves) => {
  return await post(`/api/eleves`, eleves);
});

export const addEleveNote = createAsyncThunk("posts/eleveNotes", async (note) => {
  return await post(`/api/eleves/${note.login}/notes`, note);
});


export default elevesSlice.reducer;