import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminTemplate from "./AdminTemplate";
import { get } from "../../utils/fonctions";

function migrer() {
  get('/api/migration')
}

export default function AdminOutils() {
  const dispatch = useDispatch();

  useEffect(() => {
  }, [dispatch]);

  return (
    <AdminTemplate>
      <div className="columns">
        <div className="column col-4">
          <button className="btn btn-primary longButton mt-2" onClick={() => migrer()}>
            Migration
          </button>
        </div>
      </div>
    </AdminTemplate>
  );
}
