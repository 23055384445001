//Script des requêtes
//Fonction générique pour GET
function openQuery(url, verb, defaultValue = undefined) {
  const xhttp = new XMLHttpRequest();
  const user = getUser();

  xhttp.open(verb, url);
  xhttp.setRequestHeader("Cache-Control", "no-cache");
  if (defaultValue && defaultValue["Content-Type"]) {
    if (defaultValue["Content-Type"] !== "multipart/form-data") {
      xhttp.setRequestHeader("Content-Type", defaultValue["Content-Type"]);
    }
  } else {
    xhttp.setRequestHeader("Content-Type", "application/json;charset=UTF-8");
  }

  if (user && user.token) {
    xhttp.setRequestHeader("authorization", `Bearer ${user.token}`);
  }
  if (defaultValue && defaultValue["responseType"]) {
    xhttp.responseType = defaultValue["responseType"];
  } else {
    xhttp.responseType = "json";
  }
  return xhttp;
}

export const get = (url, defaultValue = undefined) =>
  new Promise((resolve, reject) => {
    const xhttp = openQuery(url, "GET", defaultValue);
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4) {
        if (xhttp.status === 200) {
          resolve?.(xhttp.response, xhttp);
        } else {
          reject?.(xhttp.response, xhttp);
        }
      }
    };
    xhttp.send();
  });

export const post = (url, body, defaultValue = undefined) =>
  new Promise((resolve, reject) => {
    const xhttp = openQuery(url, "POST", defaultValue);
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4) {
        if (xhttp.status === 200 && !isObjEmpty(xhttp.response)) {
          resolve?.(xhttp.response, xhttp);
        } else {
          reject?.(xhttp.response, xhttp);
        }
      }
    };
    var content = body;
    if (!defaultValue || !defaultValue["Content-Type"]) {
      content = JSON.stringify(body);
    }
    xhttp.send(content);
  });

export const del = (url, defaultValue = undefined) =>
  new Promise((resolve, reject) => {
    const xhttp = openQuery(url, "DELETE", defaultValue);
    xhttp.onreadystatechange = () => {
      if (xhttp.readyState === 4) {
        if (xhttp.status === 200) {
          resolve?.(xhttp.response, xhttp);
        } else {
          reject?.(xhttp.response, xhttp);
        }
      }
    };
    xhttp.send();
  });

export const download = (fileName, blob) => {
  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.click();
  URL.revokeObjectURL(url);
};

const addHours = (date, hours) => {
  date.setTime(date.getTime() + hours * 60 * 60 * 1000);
  return date;
};

export const getUser = () => {
  try {
    const userNSIStr = localStorage?.getItem("userNSI");
    var user = JSON.parse(userNSIStr);
    const earler12hours = addHours(new Date(), -12);
    if (new Date(user.date) < earler12hours) {
      removeUser();
      return undefined;
    }
    return user;
  } catch {
    return undefined;
  }
};

export const checkIsLogged = () => {
  const user = getUser();
  return user && user.token ? true : false;
};

export const removeUser = () => {
  localStorage?.setItem("userNSI", undefined);
};

export const setUser = (user) => {
  localStorage?.setItem("userNSI", JSON.stringify(user));
};

export const clone = (obj) => {
  if (!obj) {
    return undefined;
  }
  if (typeof structuredClone === "function") {
    return structuredClone(obj);
  }
  return JSON.parse(JSON.stringify(obj));
};

function isObjEmpty(obj) {
  return Object.keys(obj).length === 0;
}

export function generatePass() {
  const str = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
  let pass = "";
  for (let i = 0; i < 8; i++) {
    const charIndex = Math.floor(Math.random() * str.length);
    pass += str.charAt(charIndex);
  }
  return pass;
}

export function cleanNom(nom) {
  const result = nom.trim().replace("  ", " ").replace(" ", "-").replace("--", "-");
  const parts = result.split("-");
  return parts.reduce((a, str) => `${a}-${str[0].toUpperCase()}${str.slice(1).toLowerCase()}`, "").substring(1);
}

export function generateLogin(nom, prenom) {
  const prenomComposeIndex = prenom.indexOf("-");
  const prenomPart = prenomComposeIndex > -1 ? prenom.substring(0, 1) + prenom.substring(prenomComposeIndex + 1, prenomComposeIndex + 2) : prenom.substring(0, 1);
  const nomComposeIndex = nom.indexOf("-");
  const nomPart = nomComposeIndex > -1 ? nom.substring(0, nomComposeIndex) : nom;
  return (prenomPart + nomPart).toLowerCase();
}

export function sortDicoIds(a, b) {
  const numberIndex = (str) => {
    var index = 0;
    while (index < str.length && !(str[index] >= "0" && str[index] <= "9")) index++;
    return index === str.length ? -1 : index;
  };
  const integerIndexA = numberIndex(a);
  const notIntegerPartA = a.substring(0, integerIndexA);
  var integerPartA = 0;
  if (integerIndexA > 0) {
    integerPartA = parseInt(a.substring(integerIndexA));
  }
  const integerIndexB = numberIndex(b);
  const notIntegerPartB = b.substring(0, integerIndexB);
  var integerPartB = 0;
  if (integerIndexB > 0) {
    integerPartB = parseInt(b.substring(integerIndexB));
  }
  const diff = notIntegerPartA.localeCompare(notIntegerPartB);
  return diff !== 0 ? diff : integerPartB - integerPartA;
}

export function sortQuestionsNumero(a, b) {
  const separatorIndex = (str) => {
    var index = 0;
    while (index < str.length && str[index] >= "0" && str[index] <= "9") index++;
    return index === str.length ? -1 : index;
  };
  const separatorIndexA = separatorIndex(a);
  const firstPartA = separatorIndexA > 0 ? parseInt(a.substring(0, separatorIndexA)) : 0;
  const secondPartA = separatorIndexA < 0 ? 0 : parseInt(a.substring(separatorIndexA + 1));
  const separatorIndexB = separatorIndex(b);
  const firstPartB = separatorIndexB > 0 ? parseInt(b.substring(0, separatorIndexB)) : 0;
  const secondPartB = separatorIndexB < 0 ? 0 : parseInt(b.substring(separatorIndexB + 1));
  const diff = firstPartA - firstPartB;
  return diff !== 0 ? diff : secondPartA - secondPartB;
}

// function dateF(n) {
//   //n est le nombre de semaine après la date attendue
//   let dateD = Date.now();
//   let dateF = dateD + n * 7 * 24 * 60 * 60 * 1000;
//   let dateFormate = new Date(dateF);
//   dateFormate = dateFormate.toLocaleDateString();

//   return dateFormate;
// }

// function strNoAccent(a) {
//   var b = "áàâäãåçéèêëíïîìñóòôöõúùûüýÁÀÂÄÃÅÇÉÈÊËÍÏÎÌÑÓÒÔÖÕÚÙÛÜÝ",
//     c = "aaaaaaceeeeiiiinooooouuuuyAAAAAACEEEEIIIINOOOOOUUUUY",
//     d = "";
//   for (var i = 0, j = a.length; i < j; i++) {
//     var e = a.substr(i, 1);
//     d += b.indexOf(e) !== -1 ? c.substr(b.indexOf(e), 1) : e;
//   }
//   return d;
// }

// function accordeon(id) {
//   var x = document.getElementById(id);
//   if (x.className.indexOf("w3-show") == -1) {
//     x.className += " w3-show";
//   } else {
//     x.className = x.className.replace(" w3-show", "");
//   }
// }
