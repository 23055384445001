import "../styles/Menu.css";

import { NavLink } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { disconnect, checkStoredUser } from "../store/user";
import { resetDevoir } from "../store/devoirs";
import { resetDico } from "../store/dicoSiiNsi";
import { resetEleves } from "../store/eleves";
import { useEffect } from "react";

const menuItems = [
  {
    to: "/",
    name: "home",
    tooltip: "Home",
    tooltipDirection: "right",
    icon: "home-fill",
    connected: false,
    admin: false,
  },
  // {
  //   to: "/exercices",
  //   name: "exercices",
  //   tooltip: "Exercices",
  //   tooltipDirection: "bottom",
  //   icon: "file-earmark-plus-fill",
  //   connected: false,
  //   admin: false,
  // },
  {
    to: "/simulation",
    name: "simulation",
    tooltip: "Simulations",
    tooltipDirection: "bottom",
    icon: "award-fill",
    connected: false,
    admin: false,
  },
  {
    to: "/python",
    name: "python",
    tooltip: "Python",
    tooltipDirection: "bottom",
    icon: "python",
    connected: false,
    admin: false,
  },
  {
    to: "/documents",
    name: "documents",
    tooltip: "Documents",
    tooltipDirection: "bottom",
    icon: "file-earmark-arrow-down-fill",
    connected: true,
    admin: true,
  },
  {
    to: "/administration",
    name: "administration",
    tooltip: "Administration",
    tooltipDirection: "bottom",
    icon: "gear-fill",
    connected: false,
    admin: true,
  },
  {
    to: "/utilisateur",
    name: "utilisateur",
    tooltip: "Utilisateur",
    tooltipDirection: "bottom",
    icon: "book-fill",
    connected: true,
    admin: false,
  },
];

export default function Menu() {
  const isConnected = useSelector((state) => state.user.isConnected);
  const isAdmin = useSelector((state) => state.user.isAdmin);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(checkStoredUser());
  }, [dispatch, isAdmin]);

  function menuItem(param) {
    const display =
      (!param.connected && !param.admin) ||
      (param.connected && isConnected && !isAdmin) ||
      (param.admin && isAdmin);

    return !display ? null : (
      <NavLink
        key={param.name}
        to={param.to}
        className={({ isActive, isPending }) =>
          isActive
            ? "text-primary m-2 tooltip tooltip-" + param.tooltipDirection
            : "text-dark m-2 tooltip tooltip-" + param.tooltipDirection
        }
        data-tooltip={param.tooltip}
      >
        <svg width="26" height="26" fill="currentColor" viewBox="0 0 16 16">
          <use xlinkHref={"/iconSprites.svg#" + param.icon} />
        </svg>
      </NavLink>
    );
  }

  function disconnectUser(e) {
    resetDevoir();
    resetDico();
    resetEleves();
    dispatch(disconnect());
  }

  return (
    <header className="navbar bg-secondary text-dark">
      <section className="navbar-section">
        {menuItems.map((m) => menuItem(m))}
      </section>
      <section className="navbar-center">
        <h4 className="mt-1">SII - NSI</h4>
      </section>
      <section className="navbar-section">
        {!isConnected ? (
          <NavLink
            to="/login"
            className="text-dark m-2 tooltip tooltip-left"
            data-tooltip="Connexion"
          >
            <span>
              <svg
                width="26"
                height="26"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <use xlinkHref="/iconSprites.svg#person-fill" />
              </svg>
            </span>
          </NavLink>
        ) : (
          <NavLink
            onClick={disconnectUser}
            to="/"
            className="text-dark m-2 tooltip tooltip-left"
            data-tooltip="Déconnexion"
          >
            <span>
              <svg
                width="26"
                height="26"
                fill="currentColor"
                viewBox="0 0 16 16"
              >
                <use xlinkHref="/iconSprites.svg#person-x-fill" />
              </svg>
            </span>
          </NavLink>
        )}
      </section>
    </header>
  );
}
