import { configureStore } from '@reduxjs/toolkit'
import userReducer from './user'
import dicoSiiNsiReducer from './dicoSiiNsi'
import elevesReducer from './eleves'
import groupesReducer from './groupes'
import devoirsReducer from './devoirs'
import notesReducer from './notes'
import coursReducer from './cours'
import documentsReducer from './documents'


export default configureStore({
  reducer: {
    user: userReducer,
    dicos: dicoSiiNsiReducer,
    eleves: elevesReducer,
    groupes: groupesReducer,
    devoirs: devoirsReducer,
    notes: notesReducer,
    cours: coursReducer,
    documents: documentsReducer,
  },
})
