import "../../styles/Devoirs.css";
import { useEffect, useState } from "react";
import Questions from "./Questions";
import Exercice from "./Exercice";
import { clone } from "../../utils/fonctions";

function calculExercice(numero) {
  var index = numero.indexOf("Q");
  if (index > 0) {
    const strValue = numero.substring(0, index);
    return parseInt(strValue);
  }
  return 0;
}

function ordonneQuestionsParExercices(devoir) {
  const questions = devoir?.questions ?? [];
  const sortQuestion = (a, b) =>
    parseInt(a?.numero?.replace("Q", "0")) -
    parseInt(b?.numero?.replace("Q", "0"));
  const result = questions
    .map((q) => {
      return { question: q, exercice: calculExercice(q?.numero ?? "0") };
    })
    .reduce(
      (a, q) => {
        if (q.exercice > 0) {
          if (a.exercices.length <= q.exercice - 1) {
            a.exercices.push({
              numero: q.exercice,
              questions: [],
              chapitres: [],
              points: 0,
            });
          }
          a.exercices[q.exercice - 1].questions.push(q.question);
          a.exercices[q.exercice - 1].points += q.question.pointQuestion;
          if (
            !a.exercices[q.exercice - 1].chapitres.includes(q.question.chapitre)
          ) {
            a.exercices[q.exercice - 1].chapitres.push(q.question.chapitre);
            a.exercices[q.exercice - 1].chapitres.sort();
          }
          a.exercices[q.exercice - 1].questions.sort(sortQuestion);
        } else {
          a.questions.push(q.question);
          a.questions.sort(sortQuestion);
        }
        return a;
      },
      { questions: [], exercices: [] }
    );
  return result;
}

export default function ExerciceQuestions({
  devoir = undefined,
  dico = undefined,
  onChange = undefined,
}) {
  const [exercices, setExercices] = useState([]);
  const [questions, setQuestions] = useState([]);

  useEffect(() => {
    var devoirCopy = clone(devoir);
    const exercices = ordonneQuestionsParExercices(devoirCopy);
    setExercices(exercices);
    setQuestions(exercices?.questions);
  }, [devoir]);

  function changeDevoir(exercices, questions) {
    var devoirCopy = clone(devoir);
    const questionsNew = exercices
      .flatMap((e) => e.questions)
      .concat(questions);
    devoirCopy.questions = questionsNew;
    devoirCopy.noteDS = questionsNew.reduce(
      (s, q) => s + (q?.pointQuestion ?? 0),
      0
    );
    onChange?.(devoirCopy);
  }

  function changeQuestions(questions) {
    questions.forEach((q, i) => (q.numero = `Q${i + 1}`));
    changeDevoir(exercices.exercices, questions);
  }

  function changeExercice(exercice) {
    var exercicesNew = exercices.exercices.map((e) =>
      e?.numero === exercice.numero ? exercice : e
    ).filter(e => (e?.questions?.length ?? 0) > 0);
    exercicesNew.forEach((e, i) => {
      e.numero = i + 1;
      e.questions.forEach((q, j) => (q.numero = `${e.numero}Q${j + 1}`));
    });
    changeDevoir(exercicesNew, questions);
  }

  function deleteExercice(exercice) {
    const exercicesCloned = clone(exercices);
    exercicesCloned.exercices = exercicesCloned.exercices.filter(
      (e) => e.numero !== exercice.numero
    );
    exercicesCloned.exercices.forEach((e, i) => {
      e.numero = i + 1;
      e.questions.forEach((q, j) => (q.numero = `${e.numero}Q${j + 1}`));
    });
    changeDevoir(exercicesCloned.exercices, questions);
  }

  function addExercice() {
    const exercicesCloned = clone(exercices);
    exercicesCloned.exercices.push({
      numero: exercicesCloned.exercices.length + 1,
      chapitres: [],
      questions: [
        {
          numero: `${exercicesCloned.exercices.length + 1}Q1`,
          pointQuestion: 1,
        },
      ],
      points: 0,
    });
    changeDevoir(exercicesCloned.exercices, questions);
  }

  return (
    <div className="columns my-2">
      {exercices?.exercices?.map((e) => (
        <Exercice
          key={"ex-" + e.numero}
          exercice={e}
          dico={dico}
          onChange={(e) => changeExercice(e)}
          onDelete={(e) => deleteExercice(e)}
        />
      ))}
      <Questions
        questions={questions}
        dico={dico}
        onChange={(q) => changeQuestions(q)}
        addExercice={() => addExercice()}
      />
    </div>
  );
}
