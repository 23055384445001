import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import AdminTemplate from "./AdminTemplate";
import { selectAllEleves, getEleves, addEleve, addEleves } from "../../store/eleves";
import { selectAllGroupes, getGroupes, addGroupe } from "../../store/groupes";
import { clone } from "../../utils/fonctions";
import ScrollView from "../ScrollView";
import List from "../List";
import Modal from "../Modal";
import Select from "../Select";

const classes = ["MP", "MPSI", "PCSI", "1"];
const matieres = ["SII", "NSI"];

export default function AdminGroupes() {
  const [modalNouveauActive, setModalNouveauActive] = useState(false);
  const [nouveauGroupe, setNouveauGroupe] = useState({});
  const [selectedGroupe, setSelectedGroupe] = useState();
  const [elevesGroupePool, setElevesGroupePool] = useState([]);
  const [selectedEleve, setSelectedEleve] = useState("");
  const [sort, setSort] = useState("");
  const [elevesChanged, setElevesChanged] = useState([]);
  const [sortArchive, setSortArchive] = useState(false);
  const [groupesSort, setGroupesSort] = useState();
  const [selectedGroupeEleves, setSelectedGroupeEleves] = useState([]);
  const groupes = useSelector(selectAllGroupes);
  const groupesStatus = useSelector((state) => state.eleves.status);
  const elevesStatus = useSelector((state) => state.eleves.status);
  const eleves = useSelector(selectAllEleves);
  const dispatch = useDispatch();

  useEffect(() => {
    if (groupesStatus === "idle") {
      dispatch(getGroupes());
    }
    if (elevesStatus === "idle") {
      dispatch(getEleves());
    }
    if (groupes) {
      var groupeSort = clone(groupes);
      if (sort) {
        groupeSort = groupeSort.filter((g) => g.nom.toUpperCase().includes(sort.toUpperCase()) || g.annee.toString().includes(sort));
      }
      if (!sortArchive) {
        groupeSort = groupeSort.filter((g) => !g.archive);
      }
      groupeSort.sort((e1, e2) => (e1.nom + e1.prenom).localeCompare(e2.nom + e2.prenom));
      setGroupesSort(groupeSort);
    }
  }, [dispatch, groupesStatus, groupes, sort, sortArchive, elevesStatus]);

  async function changeSelectedGroupe(e, groupe) {
    e.preventDefault();
    setElevesChanged([]);
    majGroupeEleves(groupe, eleves);
    setSelectedGroupe(groupe);
  }

  function majGroupeEleves(groupe, eleves) {
    setSelectedEleve("");
    const groupeEleves = eleves
      .filter((e) => e.groupes.findIndex((g) => g.nom === groupe.nom && g.annee === groupe.annee) > -1)
      .sort((e1, e2) => (e1.nom + e1.prenom).localeCompare(e2.nom + e2.prenom));
    setSelectedGroupeEleves(groupeEleves);
    const elevesGroupePool = eleves
      .filter((e) => e.groupes.findIndex((g) => g.nom === groupe.nom && g.annee === groupe.annee) < 0 && !e.archive)
      .sort((e1, e2) => (e1.nom + e1.prenom).localeCompare(e2.nom + e2.prenom));
    setElevesGroupePool(elevesGroupePool);
  }

  async function sauvegarder() {
    if (elevesChanged.length > 0) {
      await dispatch(addEleves(elevesChanged)).unwrap();      
    }
    await dispatch(addGroupe(selectedGroupe)).unwrap();
    dispatch(getGroupes());
    dispatch(getEleves());
  }

  function nouveau(value) {
    if (value) {
      setNouveauGroupe({
        classe: "",
        matiere: "SII",
        annee: new Date().getFullYear() + 1,
        numero: 1,
        archive: false,
      });
    }
    setModalNouveauActive(value);
  }

  async function validerNouveau() {
    if (
      !nouveauGroupe.classe ||
      nouveauGroupe.classe === "" ||
      !nouveauGroupe.matiere ||
      nouveauGroupe.matiere === "" ||
      !nouveauGroupe.numero ||
      nouveauGroupe.numero === "" ||
      !nouveauGroupe.annee ||
      nouveauGroupe.annee === ""
    ) {
      return;
    }
    await dispatch(addGroupe(nouveauGroupe)).unwrap();
    dispatch(getGroupes());
    setModalNouveauActive(false);
  }

  function changeAnnee(annee) {
    const cloneGroupe = clone(selectedGroupe);
    cloneGroupe.annee = annee;
    setSelectedGroupe(cloneGroupe);
  }

  function changeClasse(classe) {
    const cloneGroupe = clone(selectedGroupe);
    cloneGroupe.classe = classe;
    setSelectedGroupe(cloneGroupe);
  }

  function changeMatiere(matiere) {
    const cloneGroupe = clone(selectedGroupe);
    cloneGroupe.matiere = matiere;
    setSelectedGroupe(cloneGroupe);
  }

  function changeNumero(numero) {
    const cloneGroupe = clone(selectedGroupe);
    cloneGroupe.numero = numero > 0 ? numero : 1;
    setSelectedGroupe(cloneGroupe);
  }

  function changeArchive() {
    const cloneGroupe = clone(selectedGroupe);
    cloneGroupe.archive = !cloneGroupe.archive;
    setSelectedGroupe(cloneGroupe);
  }

  function supprimeEleve(eleve) {
    var elevesChangedClone = elevesChanged.filter(e => e.login !== eleve.login).map(e => clone(e));
    const  elevesClones = eleves.map(e => {
      const eleveChanged = elevesChangedClone.filter(c => e.login === c.login).shift();
      return eleveChanged ? eleveChanged : clone(e);
    });
    var eleveClone = elevesClones.filter(e => e.login === eleve.login).shift();
    eleveClone.groupes = eleveClone.groupes.filter(g => g.nom !== selectedGroupe.nom && g.annee !== selectedGroupe.annee);
    elevesChangedClone.push(eleveClone);
    setElevesChanged(elevesChangedClone);
    majGroupeEleves(selectedGroupe, elevesClones);
  }

  function ajouteEleve(eleve) {
    var elevesChangedClone = elevesChanged.filter(e => e.login !== eleve).map(e => clone(e));
    const  elevesClones = eleves.map(e => {
      const eleveChanged = elevesChangedClone.filter(c => e.login === c.login).shift();
      return eleveChanged ? eleveChanged : clone(e);
    });
    var eleveClone = elevesClones.filter(e => e.login === eleve).shift();
    eleveClone.groupes.push(selectedGroupe);
    elevesChangedClone.push(eleveClone);
    setElevesChanged(elevesChangedClone);
    majGroupeEleves(selectedGroupe, elevesClones);
  }

  function changeAnneeNouveau(annee) {
    const cloneGroupe = clone(nouveauGroupe);
    cloneGroupe.annee = annee;
    setNouveauGroupe(cloneGroupe);
  }

  function changeClasseNouveau(classe) {
    const cloneGroupe = clone(nouveauGroupe);
    cloneGroupe.classe = classe;
    setNouveauGroupe(cloneGroupe);
  }

  function changeMatiereNouveau(matiere) {
    const cloneGroupe = clone(nouveauGroupe);
    cloneGroupe.matiere = matiere;
    setNouveauGroupe(cloneGroupe);
  }

  function changeNumeroNouveau(numero) {
    const cloneGroupe = clone(nouveauGroupe);
    cloneGroupe.numero = numero > 0 ? numero : 1;
    setNouveauGroupe(cloneGroupe);
  }

  function calculGroupListClassname(g) {
    var className = [];
    if (g.archive) {
      className.push("text-error");
    }
    if (g._id === selectedGroupe?._id) className.push("active");
    return className.join(" ");
  }

  return (
    <AdminTemplate>
      <div className="columns">
        <div className="column col-5">
          <div>
            <button className="btn btn-success longButton mt-2" onClick={() => nouveau(true)}>
              <i className="icon icon-plus"></i> Nouveau
            </button>
          </div>
          {groupesSort && (
            <div className="mt-2">
              <div className="columns">
                <div className="column col">
                  <div className="input-group">
                    <input className="form-input" type="text" id="input-sort" placeholder="rechercher" value={sort} onChange={(e) => setSort(e.target.value)} />
                    <button className="btn btn-secondary input-group-btn" disabled={sort === ""} onClick={(e) => setSort("")}>
                      <i className="icon icon-cross"></i>
                    </button>
                  </div>
                </div>
                <div className="column col-auto">
                  <label className="form-switch is-error">
                    <input type="checkbox" id="input-archive" checked={sortArchive} onChange={() => setSortArchive(!sortArchive)} />
                    <i className="form-icon"></i>
                  </label>
                </div>
              </div>
              <ScrollView maxHeight="calc(100vh - 130px)" margin="0 0 0 0">
                <List elements={groupesSort} keyPrefix={"groupe"}>
                  {(c) => (
                    <a href={"#" + c.login} onClick={(e) => changeSelectedGroupe(e, c)} className={calculGroupListClassname(c)}>
                      <div className="columns">
                        <div className="column col-6 mt-1">{c.nom}</div>
                        <div className="column col-6 mt-1 text-right">{c.annee}</div>
                      </div>
                    </a>
                  )}
                </List>
              </ScrollView>
            </div>
          )}
        </div>
        {selectedGroupe && (
          <>
            <div className="column col-6 ml-2">
              <div className="form-group">
                <label className="form-label" forhtml="input-annee">
                  Année
                </label>
                <input className="form-input" type="number" id="input-annee" placeholder="annee" value={selectedGroupe.annee} onChange={(e) => changeAnnee(e.target.value)} />
              </div>
              <div className="form-group">
                <label className="form-label" forhtml="input-classe">
                  Classe
                </label>
                <Select elements={classes} select={selectedGroupe.classe} emptyElement={true} emptyElementLabel={"Choisir une classe"} onSelectChange={(c) => changeClasse(c)} />
              </div>
              <div className="form-group">
                <label className="form-label" forhtml="input-matiere">
                  Matière
                </label>
                <Select
                  elements={matieres}
                  select={selectedGroupe.matiere}
                  emptyElement={true}
                  emptyElementLabel={"Choisir une matière"}
                  onSelectChange={(c) => changeMatiere(c)}
                />
              </div>
              <div className="form-group">
                <label className="form-label" forhtml="input-numero">
                  Numero
                </label>
                <input className="form-input" type="number" id="input-numero" placeholder="numero" value={selectedGroupe.numero} onChange={(e) => changeNumero(e.target.value)} />
              </div>

              <div className="form-group">
                <label className="form-label" forhtml="input-archive">
                  Eleves {selectedGroupeEleves.length}
                </label>
                <div className="input-group">
                  <div className="container">
                    <ScrollView maxHeight="300" margin="0 0 0 0">
                      <List elements={selectedGroupeEleves} keyPrefix={"groupeEleve"}>
                        {(e) => (
                          <div className="columns">
                            <div className="column col">
                              {e.nom} {e.prenom}
                            </div>
                            <div className="column col">{e.login}</div>
                            <div className="column col-auto">
                              <button className="btn btn-sm btn-error input-group-btn s-circle" onClick={(x) => supprimeEleve(e)}>
                                <i className="icon icon-cross"></i>
                              </button>
                            </div>
                          </div>
                        )}
                      </List>
                    </ScrollView>
                    <div className="columns m-2">
                      <Select
                        elements={elevesGroupePool}
                        select={selectedEleve}
                        emptyElement={true}
                        emptyElementLabel={"Choisir un eleve"}
                        keySelector={(e) => e.login}
                        valueSelector={(e) => e.login}
                        labelSelector={(e) => `${e.nom} ${e.prenom}`}
                        onSelectChange={(e) => setSelectedEleve(e)}
                      />
                      <button className="btn btn-success input-group-btn" disabled={selectedEleve === ""} onClick={(e) => ajouteEleve(selectedEleve)}>
                        <i className="icon icon-plus"></i>
                      </button>
                    </div>
                  </div>
                </div>
              </div>

              <div className="form-group">
                <label className="form-label" forhtml="input-archive">
                  Archiver
                </label>
                <label className="form-switch is-error">
                  <input type="checkbox" id="input-archive" checked={selectedGroupe.archive} onChange={() => changeArchive()} />
                  <i className="form-icon"></i>
                </label>
              </div>
              <button className="btn btn-primary longButton mt-2" onClick={() => sauvegarder()}>
                Sauvegarder
              </button>
            </div>
          </>
        )}
        <Modal title="Nouveaux groupe" keySuffix="copieModal" active={modalNouveauActive} onCancel={() => nouveau(false)} onValidate={() => validerNouveau()}>
          <div className="form-group">
            <label className="form-label" forhtml="input-annee">
              Année
            </label>
            <input className="form-input" type="number" id="input-annee" placeholder="annee" value={nouveauGroupe.annee} onChange={(e) => changeAnneeNouveau(e.target.value)} />
          </div>
          <div className="form-group">
            <label className="form-label" forhtml="input-classe">
              Classe
            </label>
            <Select elements={classes} select={nouveauGroupe.classe} emptyElement={true} emptyElementLabel={"Choisir une classe"} onSelectChange={(c) => changeClasseNouveau(c)} />
          </div>
          <div className="form-group">
            <label className="form-label" forhtml="input-matiere">
              Matière
            </label>
            <Select
              elements={matieres}
              select={nouveauGroupe.matiere}
              emptyElement={true}
              emptyElementLabel={"Choisir une matière"}
              onSelectChange={(c) => changeMatiereNouveau(c)}
            />
          </div>
          <div className="form-group">
            <label className="form-label" forhtml="input-numero">
              Numero
            </label>
            <input className="form-input" type="number" id="input-numero" placeholder="numero" value={nouveauGroupe.numero} onChange={(e) => changeNumeroNouveau(e.target.value)} />
          </div>
        </Modal>
      </div>
    </AdminTemplate>
  );
}
