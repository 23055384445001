import UtilisateurMenu from "./UtilisateurMenu";

export default function AdminTemplate({ children }) {
  return (
    <div className="container">
      <div className="columns">
        <div className="column col-2 col-md-3 col-sm-12">
          <UtilisateurMenu />
        </div>
        <div className="column col-10 col-md-9 col-sm-12">{children}</div>
      </div>
    </div>
  );
}
