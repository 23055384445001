import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import { get, sortDicoIds } from "../utils/fonctions";


function creationDonneesNSI(dicoNSI) {
  dicoNSI.sort((a, b) => sortDicoIds(a.id, b.id));
  dicoNSI.forEach(chap => {
    chap.label = `${chap.id} - ${chap.nom}`;
    chap.contenus.forEach((c) => c.label = `${c.nom} - ${c.libelle}`)
  });
  return {
    dico: dicoNSI,

    data: [
      {
        type: "sunburst",
        ids: dicoNSI.map((x) => x.id),
        labels: dicoNSI.map((x) => x.id),
        hovertext: dicoNSI.map((x) => x.nom),
        parents: dicoNSI.map((x) => x.parent),
        outsidetextfont: { size: 20, color: "#377eb8" },
        hoverinfo: "text",
        marker: { line: { width: 2 } },
        branchvalues: "remainder",
      },
    ],
    layout: {
      margin: { l: 0, r: 0, b: 0, t: 0 },
      sunburstcolorway: ["#0B2027", "#40798C", "#70A9A1", "#CFD7C7"],
      autosize: true
    },
    useResizeHandler: true,
    style: {width: "100%", height: "100%"}
  };
}

function creationDonneesSII(dicoSII) {
  dicoSII.sort((a, b) => sortDicoIds(a.id, b.id));
  dicoSII.forEach(chap => {
    chap.label = `${chap.id} - ${chap.nom}`;
    chap.contenus.forEach((c) => c.label = `${c.nom} - ${c.libelle}`)
  });
  return {
    dico: dicoSII,
    data: [
      {
        type: "sunburst",
        ids: dicoSII.map((x) => x.id),
        labels: dicoSII.map((x) => x.id),
        hovertext: dicoSII.map((x) => x.nom),
        parents: dicoSII.map((x) => x.parent),
        outsidetextfont: { size: 20, color: "#377eb8" },
        hoverinfo: "text",
        marker: { line: { width: 2 } },
        branchvalues: "remainder",
      },
    ],
    layout: {
      margin: { l: 0, r: 0, b: 0, t: 0 },
      sunburstcolorway: ["#1da9db", "#66B3BA", "#8EB19D", "#F6EFA6", "#F0D2D1"],
      autosize: true
    },
    useResizeHandler: true,
    style: {width: "100%", height: "100%"}
  };
}

export const dicoSiiNsiSlice = createSlice({
  name: "dicoSiiNsi",
  initialState: {
    
    dicoSII: undefined,
    statusDicoSII: "idle",
    errorSII: null,

    dicoNSI: undefined,
    statusDicoNSI: "idle",
    errorNSI: null,

  },
  reducers: {
    resetDico: (state) => {
      state.statusDicoSII = "idle";
      state.statusDicoNSI = "idle";
      state.dicoSII = [];
      state.dicoNSI = [];
      state.errorSII = null;
      state.errorNSI = null;
    },
  },
  extraReducers(builder) {
    builder
      .addCase(getDicoSII.pending, (state, action) => {
        state.statusDicoSII = "loading";
      })
      .addCase(getDicoSII.fulfilled, (state, action) => {
        state.statusDicoSII = "succeeded";
        state.dicoSII = creationDonneesSII(action.payload);
      })
      .addCase(getDicoSII.rejected, (state, action) => {
        state.statusDicoSII = "failed";
        state.errorSII = action.error.message;
      })

      .addCase(getDicoNSI.pending, (state, action) => {
        state.statusDicoNSI = "loading";
      })
      .addCase(getDicoNSI.fulfilled, (state, action) => {
        state.statusDicoNSI = "succeeded";
        state.dicoNSI = creationDonneesNSI(action.payload);
      })
      .addCase(getDicoNSI.rejected, (state, action) => {
        state.statusDicoNSI = "failed";
        state.errorNSI = action.error.message;
      })
  },
})

export const { resetDico } = dicoSiiNsiSlice.actions;

export const getDicoSII = createAsyncThunk("get/dicosSII", async () => {
  return await get("/api/dicos/SII");
});

export const getDicoNSI = createAsyncThunk("get/dicosNSI", async () => {
  return await get("/api/dicos/NSI");
});

export default dicoSiiNsiSlice.reducer