export default class QuestionDevoir {
    numero = "";
    chapitre = "";
    notion = "";
    competence = "";
    pointQuestion = 0;
    pointEleve = 0;
    acquis = false;
  
    constructor({
      numero = "",
      chapitre = "",
      notion = "",
      competence = "",
      pointQuestion = 0,
      pointEleve = 0,
      acquis = false,
    }) {
      this.numero = numero;
      this.chapitre = chapitre;
      this.notion = notion;
      this.competence = competence;
      this.pointQuestion = pointQuestion;
      this.pointEleve = pointEleve;
      this.acquis = acquis;
    }
}