//Paramètres de tracé des hexagones
const a = (2 * Math.PI) / 6;
const r = 40;

//Définition des diff. hex. avec [x, y (pos du centre), nom de l'hex, valeur préaffectée pour la distance du clic]
export const dicoCarte = {
  "03": { x: r + 3 * r * (1 + Math.cos(a)), y: r + r * Math.sin(a), chapitre: "SLCI2", dist: 1000 },
  "05": { x: r + 5 * r * (1 + Math.cos(a)), y: r + r * Math.sin(a), chapitre: "IS1", dist: 1000 },
  11: { x: r + r * (1 + Math.cos(a)), y: r + 3 * r * Math.sin(a), chapitre: "SLCI4", dist: 1000 },
  12: { x: r + 2 * r * (1 + Math.cos(a)), y: r + 2 * r * Math.sin(a), chapitre: "SLCI3", dist: 1000 },
  14: { x: r + 4 * r * (1 + Math.cos(a)), y: r + 2 * r * Math.sin(a), chapitre: "SLCI1", dist: 1000 },
  15: { x: r + 5 * r * (1 + Math.cos(a)), y: r + 3 * r * Math.sin(a), chapitre: "IS2", dist: 1000 },
  17: { x: r + 7 * r * (1 + Math.cos(a)), y: r + 3 * r * Math.sin(a), chapitre: "IN1", dist: 1000 },
  19: { x: r + 9 * r * (1 + Math.cos(a)), y: r + 3 * r * Math.sin(a), chapitre: "IN6", dist: 1000 },
  20: { x: r, y: r + 4 * r * Math.sin(a), chapitre: "SLCI11", dist: 1000 },
  21: { x: r + r * (1 + Math.cos(a)), y: r + 5 * r * Math.sin(a), chapitre: "SLCI6", dist: 1000 },
  22: { x: r + 2 * r * (1 + Math.cos(a)), y: r + 4 * r * Math.sin(a), chapitre: "SLCI5", dist: 1000 },
  24: { x: r + 4 * r * (1 + Math.cos(a)), y: r + 4 * r * Math.sin(a), chapitre: "SED", dist: 1000 },
  25: { x: r + 5 * r * (1 + Math.cos(a)), y: r + 5 * r * Math.sin(a), chapitre: "IS3", dist: 1000 },
  26: { x: r + 6 * r * (1 + Math.cos(a)), y: r + 4 * r * Math.sin(a), chapitre: "IS4", dist: 1000 },
  27: { x: r + 7 * r * (1 + Math.cos(a)), y: r + 5 * r * Math.sin(a), chapitre: "IN2", dist: 1000 },
  28: { x: r + 8 * r * (1 + Math.cos(a)), y: r + 4 * r * Math.sin(a), chapitre: "IN5", dist: 1000 },
  29: { x: r + 9 * r * (1 + Math.cos(a)), y: r + 5 * r * Math.sin(a), chapitre: "IN4", dist: 1000 },
  30: { x: r, y: r + 6 * r * Math.sin(a), chapitre: "SLCI7", dist: 1000 },
  31: { x: r + r * (1 + Math.cos(a)), y: r + 7 * r * Math.sin(a), chapitre: "SLCI8", dist: 1000 },
  32: { x: r + 2 * r * (1 + Math.cos(a)), y: r + 6 * r * Math.sin(a), chapitre: "SLCI9", dist: 1000 },
  35: { x: r + 5 * r * (1 + Math.cos(a)), y: r + 7 * r * Math.sin(a), chapitre: "MEC1", dist: 1000 },
  38: { x: r + 8 * r * (1 + Math.cos(a)), y: r + 6 * r * Math.sin(a), chapitre: "IN3", dist: 1000 },
  40: { x: r, y: r + 8 * r * Math.sin(a), chapitre: "SLCI10", dist: 1000 },
  41: { x: r + r * (1 + Math.cos(a)), y: r + 9 * r * Math.sin(a), chapitre: "SLCI10", dist: 1000 },
  42: { x: r + 2 * r * (1 + Math.cos(a)), y: r + 8 * r * Math.sin(a), chapitre: "SLCI10", dist: 1000 },
  44: { x: r + 4 * r * (1 + Math.cos(a)), y: r + 8 * r * Math.sin(a), chapitre: "STAT1", dist: 1000 },
  45: { x: r + 5 * r * (1 + Math.cos(a)), y: r + 9 * r * Math.sin(a), chapitre: "MEC2", dist: 1000 },
  46: { x: r + 6 * r * (1 + Math.cos(a)), y: r + 8 * r * Math.sin(a), chapitre: "CIN1", dist: 1000 },
  47: { x: r + 7 * r * (1 + Math.cos(a)), y: r + 9 * r * Math.sin(a), chapitre: "CIN3", dist: 1000 },
  54: { x: r + 4 * r * (1 + Math.cos(a)), y: r + 10 * r * Math.sin(a), chapitre: "STAT2", dist: 1000 },
  55: { x: r + 5 * r * (1 + Math.cos(a)), y: r + 11 * r * Math.sin(a), chapitre: "DYN2", dist: 1000 },
  56: { x: r + 6 * r * (1 + Math.cos(a)), y: r + 10 * r * Math.sin(a), chapitre: "CIN2", dist: 1000 },
  64: { x: r + 4 * r * (1 + Math.cos(a)), y: r + 12 * r * Math.sin(a), chapitre: "DYN1", dist: 1000 },
  66: { x: r + 6 * r * (1 + Math.cos(a)), y: r + 12 * r * Math.sin(a), chapitre: "DYN3", dist: 1000 },
};

//Définition des antécédents pour chaque hex, avec le point de départ du triangle => flèche
//Sur cercle trigo : 1/12 = 30° ; 1/4 = 90° (vers le haut) ; 5/12 = 150° ; 7/12 = 210° ; 3/4 = 270° (vers le bas) ; 11/12 = 330°
const dicoAntecedent = {
  "03": [{ prec: "14", alpha: 2 * Math.PI * (5 / 12) }],
  "05": [],
  11: [{ prec: "12", alpha: 2 * Math.PI * (7 / 12) }],
  12: [{ prec: "03", alpha: 2 * Math.PI * (7 / 12) }],
  14: [{ prec: "15", alpha: 2 * Math.PI * (5 / 12) }],
  15: [{ prec: "05", alpha: 2 * Math.PI * (3 / 4) }],
  17: [],
  19: [{ prec: "28", alpha: 2 * Math.PI * (1 / 12) }],
  20: [{ prec: "21", alpha: 2 * Math.PI * (5 / 12) }],
  21: [
    { prec: "11", alpha: 2 * Math.PI * (3 / 4) },
    { prec: "22", alpha: 2 * Math.PI * (7 / 12) },
  ],
  22: [{ prec: "12", alpha: 2 * Math.PI * (3 / 4) }],
  24: [
    { prec: "15", alpha: 2 * Math.PI * (7 / 12) },
    { prec: "25", alpha: 2 * Math.PI * (5 / 12) },
  ],
  25: [{ prec: "15", alpha: 2 * Math.PI * (3 / 4) }],
  26: [{ prec: "15", alpha: 2 * Math.PI * (11 / 12) }],
  27: [
    { prec: "26", alpha: 2 * Math.PI * (11 / 12) },
    { prec: "17", alpha: 2 * Math.PI * (3 / 4) },
  ],
  28: [{ prec: "29", alpha: 2 * Math.PI * (5 / 12) }],
  29: [{ prec: "38", alpha: 2 * Math.PI * (1 / 12) }],
  30: [{ prec: "21", alpha: 2 * Math.PI * (7 / 12) }],
  31: [{ prec: "21", alpha: 2 * Math.PI * (3 / 4) }],
  32: [{ prec: "21", alpha: 2 * Math.PI * (11 / 12) }],
  35: [{ prec: "25", alpha: 2 * Math.PI * (3 / 4) }],
  38: [{ prec: "27", alpha: 2 * Math.PI * (11 / 12) }],
  40: [{ prec: "30", alpha: 2 * Math.PI * (3 / 4) }],
  41: [{ prec: "31", alpha: 2 * Math.PI * (3 / 4) }],
  42: [{ prec: "32", alpha: 2 * Math.PI * (3 / 4) }],
  44: [{ prec: "35", alpha: 2 * Math.PI * (7 / 12) }],
  45: [
    { prec: "44", alpha: 2 * Math.PI * (11 / 12) },
    { prec: "56", alpha: 2 * Math.PI * (5 / 12) },
  ],
  46: [{ prec: "35", alpha: 2 * Math.PI * (11 / 12) }],
  47: [{ prec: "56", alpha: 2 * Math.PI * (1 / 12) }],
  54: [{ prec: "44", alpha: 2 * Math.PI * (3 / 4) }],
  55: [
    { prec: "54", alpha: 2 * Math.PI * (11 / 12) },
    { prec: "56", alpha: 2 * Math.PI * (7 / 12) },
    { prec: "64", alpha: 2 * Math.PI * (1 / 12) },
  ],
  56: [{ prec: "46", alpha: 2 * Math.PI * (3 / 4) }],
  64: [],
  66: [{ prec: "55", alpha: 2 * Math.PI * (11 / 12) }],
};

export function drawGrid(ctx, width, height, selectedHex) {
  ctx.clearRect(0, 0, width, height);
  for (var hex in dicoCarte) {
    const enCours = false; //hex === hexEnCours;
    const selectionne = hex === selectedHex;

    var opacity = selectionne ? 1 : 0.3;
    var text = dicoCarte[hex].chapitre; // + '-' + hex;
    const textColor = selectionne ? "white" : "black";
    let color = "";
    if (dicoCarte[hex].chapitre.indexOf("SLCI") !== -1) {
      color = "#96CBD0";
    } else if (dicoCarte[hex].chapitre.indexOf("SED") !== -1) {
      color = "#96CBD0";
    } else if (dicoCarte[hex].chapitre.indexOf("IS") !== -1) {
      color = "#B1C9BB";
    } else if (dicoCarte[hex].chapitre.indexOf("MEC") !== -1) {
      color = "#65C5E7";
    } else if (dicoCarte[hex].chapitre.indexOf("DYN") !== -1) {
      color = "#65C5E7";
    } else if (dicoCarte[hex].chapitre.indexOf("STAT") !== -1) {
      color = "#65C5E7";
    } else if (dicoCarte[hex].chapitre.indexOf("CIN") !== -1) {
      color = "#65C5E7";
    } else if (dicoCarte[hex].chapitre.indexOf("IN") !== -1) {
      color = "#E0DBAD";
    }
    drawHexagon(ctx, dicoCarte[hex].x, dicoCarte[hex].y, r - 5, color, textColor, text, opacity);

    if (selectionne) {
      drawHexagon(ctx, dicoCarte[hex].x, dicoCarte[hex].y, r, color, textColor, text, 0.4);
    } else if (enCours) {
      drawHexagon(ctx, dicoCarte[hex].x, dicoCarte[hex].y, r, "#7FB3D5", textColor, text, 0.6);
      // } else if (hexEval.indexOf(hex) !== -1) {
      //   drawHexagon(ctx, dicoCarte[hex].x, dicoCarte[hex].y, r, color, textColor, text, 0.6);
    }
    drawArrow(ctx, hex, opacity);
  }
}

//Fonction de dessin d'un hexagone
function drawHexagon(ctx, x, y, rayon, fillColor, textColor, text, opacity) {
  ctx.beginPath();
  for (let i = 0; i < 6; i++) {
    ctx.lineTo(x + rayon * Math.cos(a * i), y + rayon * Math.sin(a * i));
  }
  ctx.globalAlpha = opacity;
  ctx.closePath();
  ctx.strokeStyle = "white";
  ctx.stroke();
  ctx.fillStyle = fillColor;
  ctx.fill();

  ctx.font = "15px arial";
  ctx.fillStyle = textColor;
  ctx.textAlign = "center";
  ctx.fillText(text, x, y);
}

//Fonction qui trace les triangles en fonction du point de départ et de l'orientation
function drawArrow(ctx, hexSelectionne, opacity) {
  for (let hex in dicoAntecedent) {
    const xA = dicoCarte[hex].x;
    const yA = dicoCarte[hex].y;
    dicoAntecedent[hex].forEach((ent) => {
      if (hexSelectionne !== ent.prec) return;
      const xB = dicoCarte[ent.prec].x;
      const yB = dicoCarte[ent.prec].y;
      const posCentreX = xA + (xB - xA) / 2;
      const posCentreY = yA + (yB - yA) / 2;

      drawTriangle(ctx, posCentreX, posCentreY, ent.alpha, opacity);
    });
  }
}
//Fonction qui trace les triangles => flèches
function drawTriangle(ctx, x, y, alpha, opacity) {
  const d = 15;
  ctx.globalAlpha = opacity;
  ctx.fillStyle = "#545552";
  ctx.strokeStyle = "white";
  ctx.beginPath();
  ctx.moveTo(
    x - (d / 2) * Math.cos(alpha) - d * Math.tan((2 * Math.PI) / 12) * Math.sin(alpha),
    y + (d / 2) * Math.sin(alpha) - d * Math.tan((2 * Math.PI) / 12) * Math.cos(alpha)
  );
  ctx.lineTo(
    x - (d / 2) * Math.cos(alpha) + d * Math.tan((2 * Math.PI) / 12) * Math.sin(alpha),
    y + (d / 2) * Math.sin(alpha) + d * Math.tan((2 * Math.PI) / 12) * Math.cos(alpha)
  );
  ctx.lineTo(x + (d / 2) * Math.cos(alpha), y - (d / 2) * Math.sin(alpha));
  ctx.lineTo(
    x - (d / 2) * Math.cos(alpha) - d * Math.tan((2 * Math.PI) / 12) * Math.sin(alpha),
    y + (d / 2) * Math.sin(alpha) - d * Math.tan((2 * Math.PI) / 12) * Math.cos(alpha)
  );

  ctx.moveTo(
    x - (d / 2) * Math.cos(alpha) - (d + 10) * Math.tan((2 * Math.PI) / 12) * Math.sin(alpha),
    y + (d / 2) * Math.sin(alpha) - (d + 10) * Math.tan((2 * Math.PI) / 12) * Math.cos(alpha)
  );
  ctx.lineTo(
    x - (d / 2) * Math.cos(alpha) + (d + 10) * Math.tan((2 * Math.PI) / 12) * Math.sin(alpha),
    y + (d / 2) * Math.sin(alpha) + (d + 10) * Math.tan((2 * Math.PI) / 12) * Math.cos(alpha)
  );
  ctx.fill();
  ctx.stroke();
}

//Fonction qui détermine
export function minimum() {
  var hexMini = "05";
  for (var hex in dicoCarte) {
    if (dicoCarte[hex].dist < dicoCarte[hexMini].dist) {
      hexMini = hex;
    }
  }
  return hexMini;
}