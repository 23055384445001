import '../styles/Menu.css';

import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <div className="text-center stickyBottom">
      <Link
        to="/contact"
        className="text-dark tooltip tooltip-top"
        data-tooltip="Contact"
      >
        <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <use xlinkHref="/iconSprites.svg#envelope-at-fill" />
        </svg>
      </Link>
      <span className="text-tiny footerText" >
        <strong >Lycée Pierre d'Ailly - Mme LEVEUGLE</strong>
      </span>
      <Link
        to="/arts"
        className="text-dark tooltip tooltip-top"
        data-tooltip="Arts"
      >
        <svg width="16" height="16" fill="currentColor" viewBox="0 0 16 16">
          <use xlinkHref="/iconSprites.svg#palette-fill" />
        </svg>
      </Link>
    </div>
  );
}
