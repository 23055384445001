import "../../styles/Devoirs.css";
import { useEffect, useState } from "react";
import Select from "../Select";
import { clone } from "../../utils/fonctions";

function remplissageChapitres(dico) {
  if (!dico) return [];

  const dicoChapFiltre = dico.filter((chap) => chap.cours);

  return dicoChapFiltre
    .map((e, i) => {
      return { index: i, value: e.id };
    })
    .sort((a, b) => a.value.localeCompare(b.value))
    .map((e) => dicoChapFiltre[e.index]);
}

export default function Question({
  question = undefined,
  dico = undefined,
  onDragStart = undefined,
  onDragEnter = undefined,
  onDragEnd = undefined,
  onDelete = undefined,
  onChange = undefined,
}) {
  const [chapitre, setChapitre] = useState("");
  const [competence, setCompetence] = useState("");
  const [notion, setNotion] = useState("");
  const [points, setPoints] = useState(0);

  useEffect(() => {
    setChapitre(question.chapitre);
    setCompetence(question.competence);
    setNotion(question.notion);
    setPoints(question.pointQuestion);
  }, [question]);

  const chapitresSII = remplissageChapitres(dico?.dico);
  const competences =
    dico?.dico?.filter((chap) => chap.id === question.chapitre)?.shift()
      ?.contenus ?? [];
  const notions =
    dico?.dico?.filter((chap) => chap.id === question.chapitre)?.shift()
      ?.notions ?? [];

  function changeChapitre(question, c) {
    if (question.chapitre !== c) {
      var questionClone = clone(question);
      questionClone.chapitre = c;

      const competences =
        dico?.dico?.filter((chap) => chap.id === c)?.shift()?.contenus ?? [];
      const competence = competences.length === 1 ? competences[0].nom : "";
      questionClone.competence = competence;

      const notions =
        dico?.dico?.filter((chap) => chap.id === c)?.shift()?.notions ?? [];
      const notion = notions.length === 1 ? notions[0] : "";
      questionClone.notion = notion;
      onChange?.(questionClone);
    }
  }

  function changeCompetence(question, c) {
    if (question.competence !== c) {
      var questionClone = clone(question);
      questionClone.competence = c;
      onChange?.(questionClone);
    }
  }

  function changeNotion(question, c) {
    if (question.notion !== c) {
      var questionClone = clone(question);
      questionClone.notion = c;
      onChange?.(questionClone);
    }
  }

  function changePoints(question, c) {
    if (question.pointQuestion !== c && c !== "") {
      var points = parseInt(c);
      if (points < 1) points = 1;
      var questionClone = clone(question);
      questionClone.pointQuestion = points;
      onChange?.(questionClone);
    } else if (c === "") {
      setPoints("");
    }
  }

  return (
    <div
      className="column col-12 question"
      draggable
      onDragStart={(e) => onDragStart?.(question, e)}
      onDragEnter={(e) => onDragEnter?.(question, e)}
      onDragEnd={(e) => onDragEnd?.(question, e)}
      onDragOver={(e) => e.preventDefault()}
    >
      <div className="columns">
        <div className="column col-auto mt-2">
          <div className="dragElement">
            <i className="icon icon-resize-vert"></i>
            <span className="numeroQ">{question.numero}</span>
          </div>
        </div>
        <div className="column col-3 col-lg-5 col-md-8 mt-2">
          <Select
            elements={chapitresSII}
            select={chapitre}
            emptyElement={true}
            emptyElementLabel={"Choisir un chapitre"}
            onSelectChange={(c) => changeChapitre(question, c)}
            keySelector={(c) => c.id}
            valueSelector={(c) => c.id}
            labelSelector={(c) => c.label}
          />
        </div>
        <div className="column col-3 col-lg-5 col-md-6 mt-2">
          <Select
            elements={competences}
            select={competence}
            emptyElement={true}
            emptyElementLabel={"Choisir une compétence"}
            onSelectChange={(c) => changeCompetence(question, c)}
            keySelector={(c) => c.nom}
            valueSelector={(c) => c.nom}
            labelSelector={(c) => c.label}
          />
        </div>
        <div className="column col-3 col-lg-5 col-md-6 mt-2">
          <Select
            elements={notions}
            select={notion}
            emptyElement={true}
            emptyElementLabel={"Choisir une notion"}
            onSelectChange={(c) => changeNotion(question, c)}
          />
        </div>
        <div className="column col col-lg-5 col-md-6 mt-2">
          <div className="input-group">
            <input
              className="form-input"
              type="number"
              value={points}
              onChange={(e) => changePoints(question, e.target.value)}
            />
            <span className="input-group-addon addon-sm">pts</span>
          </div>
        </div>
        <div className="column col-auto mt-2">
          <button
            className="btn btn-action btn-Error s-circle"
            onClick={() => onDelete?.(question)}
          >
            <i className="icon icon-cross"></i>
          </button>
        </div>
      </div>
    </div>
  );
}
