import QuestionNote from "./QuestionNote";

export default class Note {
    _id = "";
    login = "";
    nom = "";
    prenom = "";
    matiere = "";
    groupe = "";
    date = "";
    sujet = "";
    noteDS = 0;
    noteEleve = 0;
    questions = [];
  
    constructor({
      _id = "",
      login = "",
      nom = "",
      prenom = "",
      matiere = "",
      groupe = "",
      date = "",
      sujet = "",
      noteDS = 0,
      noteEleve = 0,
      questions = [],
    }) {
      this._id = _id;
      this.login = login;
      this.nom = nom;
      this.prenom = prenom;
      this.matiere = matiere;
      this.groupe = groupe;
      this.date = date;
      this.sujet = sujet;
      this.noteDS = noteDS;
      this.noteEleve = noteEleve;
      this.questions = questions.map(q => new QuestionNote(q));;
    }

    get estEvaluer() {
      return this._id !== "";
    }

    get nomFull() {
      return `${this.date} - ${this.groupe} - ${this.sujet}`;
    }
  
    get classeSeulement() {
      return this.groupe?.substring(0, this.groupe?.indexOf("_")) ?? "";
    }
    set classeSeulement(value) {
      this.groupe = `${value}_SII`;
    }

}